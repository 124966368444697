
























































































































































@import "../../styles/env";

.intro {
  margin-bottom: $gutter;
}

.extra {
  margin-top: $gutter;

  .block {
    margin-top: 1rem;
    padding-left: 7rem;

    display: block;

    position: relative;

    color: $base-font-colour;

    background-color: #FFF;

    &.facebook,
    &.skype {
      color: #FFF;
    }

    &.facebook {
      background-color: $facebook;
    }

    &.skype {
      background-color: $skype;
    }

    &:hover {
      color: #FFF;

      background-color: $base-font-colour;
    }

    .fab,
    .material-icons {
      margin-top: -1.5rem;

      position: absolute;
      top: 50%;
      left: 2rem;

      font-size: 3rem;
    }
  }
}
